<div class="wrapper-term">
  <div #term class="term" (scroll)="onScroll($event)">
    <ng-content></ng-content>
  </div>
  <button class="scroll-down-button" (click)="onScrollDownButtonClick()" [disabled]="isScrollDownButtonDisabled">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect *ngIf="isScrollDownButtonDisabled" y="-0.00012207" width="30" height="30" rx="4" fill="#C2CCD6"/>
      <rect *ngIf="!isScrollDownButtonDisabled" y="-0.00012207" width="30" height="30" rx="4" fill="#08068D"/>
      <path *ngIf="!isScrollDownButtonDisabled" d="M20.5586 14.6132C20.6953 14.7225 20.6953 14.9413 20.5586 15.078L14.8438 20.7928C14.707 20.9296 14.5156 20.9296 14.3789 20.7928L8.66406 15.078C8.52734 14.9413 8.52734 14.7225 8.66406 14.6132L9.18359 14.0663C9.32031 13.9296 9.53906 13.9296 9.64844 14.0663L13.9141 18.3046V8.953C13.9141 8.78894 14.0508 8.62488 14.2422 8.62488H15.0078C15.1719 8.62488 15.3359 8.78894 15.3359 8.953V18.3046L19.5742 14.0663C19.6836 13.9296 19.9023 13.9296 20.0391 14.0663L20.5586 14.6132Z" fill="white"/>
      <path *ngIf="isScrollDownButtonDisabled" d="M20.5586 15.1133C20.6953 15.2227 20.6953 15.4414 20.5586 15.5781L14.8438 21.293C14.707 21.4297 14.5156 21.4297 14.3789 21.293L8.66406 15.5781C8.52734 15.4414 8.52734 15.2227 8.66406 15.1133L9.18359 14.5664C9.32031 14.4297 9.53906 14.4297 9.64844 14.5664L13.9141 18.8047V9.45312C13.9141 9.28906 14.0508 9.125 14.2422 9.125H15.0078C15.1719 9.125 15.3359 9.28906 15.3359 9.45312V18.8047L19.5742 14.5664C19.6836 14.4297 19.9023 14.4297 20.0391 14.5664L20.5586 15.1133Z" fill="white"/>
    </svg>
  </button>
</div>
