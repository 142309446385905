import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coupon-alert',
  templateUrl: './coupon-alert.component.html',
  styleUrls: ['./coupon-alert.component.scss']
})
export class CouponAlertComponent {
  @Input() iconName!: string;
  @Input() iconColor: string = '#000'
  @Input() title!: string;
  @Input() message?: string;
}
