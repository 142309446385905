<div class="card" [ngClass]="{ iframe: viewIsIframe }">
  <div class="wrapper-section">
    <div
      class="wrapper-notification"
      *ngIf="incompleteDeal && contactAlreadyExist"
    >
      <div class="notification">
        <span> Continue seu cadastro para economizar com a Bulbe. </span>
      </div>
    </div>

    <app-title
      [showHelpText]="true"
      [currentStep]="3"
      [title]="getTitle()"
    ></app-title>

    <form [formGroup]="thirdForm">
      <p class="button-wrapper-text">
        Onde você quer economizar? <span class="prop-required">*</span>
      </p>
      <div class="button-container">
        <button
          type="button"
          class="select-btn"
          [ngClass]="{
            selected: isSelectedForHome,
            unselected: !isSelectedForHome && isSelectedForCompany
          }"
          (click)="selectHome()"
        >
          Casa
        </button>

        <button
          type="button"
          class="select-btn"
          [ngClass]="{
            selected: isSelectedForCompany,
            unselected: isSelectedForHome && !isSelectedForCompany
          }"
          (click)="selectCompany()"
        >
          Empresa
        </button>
      </div>

      <div class="section-form" *ngIf="isSelectedForHome">
        <div class="group-form">
          <label for="myCpf">
            Meu CPF <span class="prop-required">*</span>
          </label>
          <div class="group-container">
            <div>
              <input
                type="myCpf"
                maxlength="14"
                class="input md my-cpf"
                id="myCpf"
                formControlName="myCpf"
                placeholder="000.000.000-00"
                mask="000.000.000-00"
              />
              <p class="error-message sm" *ngIf="showMyCpfError">
                CPF inválido.
              </p>
              <p class="error-message sm" *ngIf="showMyCpfErrorAlreadyExist">
                CPF já foi cadastrado.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isSelectedForCompany">
        <div class="group-form">
          <label for="cnpj" class="form-label">
            CNPJ do titular da conta de luz
            <span class="prop-required">*</span>
          </label>
          <input
            type="cnpj"
            maxlength="18"
            class="input md"
            id="cnpj"
            formControlName="cnpj"
            placeholder="00.000.000/0000-00"
            mask="00.000.000/0000-00"
          />
          <p class="error-message sm" *ngIf="showCnpjError">CNPJ inválido.</p>
          <p
            class="error-message sm"
            *ngIf="!showCnpjError && showDuplicatedCnpjError"
          >
            CNPJ já cadastrado.
          </p>
        </div>
      </div>

      <div class="group-form secondy" *ngIf="isSelectedForHome">
        <p class="button-wrapper-text">
          A conta de luz está em seu nome? <span class="prop-required">*</span>
        </p>
        <div class="button-container" *ngIf="isSelectedForHome">
          <button
            type="button"
            class="select-btn"
            [ngClass]="{
              selected: isElectricBillOwner,
              unselected: isElectricBillOwner === false
            }"
            (click)="selectElectricBillOwner()"
          >
            Sim
          </button>

          <button
            type="button"
            class="select-btn"
            [ngClass]="{
              selected: isElectricBillOwner === false,
              unselected: isElectricBillOwner
            }"
            (click)="selectElectricBillOwner(false)"
          >
            Não
          </button>
        </div>

        <div class="wrapper-label" *ngIf="isElectricBillOwner === false">
          <label
            *ngIf="!userDontKnowCpf"
            for="cpf"
            class="form-label"
            id="document-input-label"
          >
            CPF do titular da conta de luz <span class="prop-required">*</span>
          </label>
          <label
            *ngIf="userDontKnowCpf"
            class="form-label"
            id="document-input-label"
          >
            Conta de luz <span class="prop-required">*</span>
          </label>
          <span
            class="wrapper-badge"
            *ngIf="viewIsIframe"
            id="btn-info-cpf-fatura-badge"
          >
            <app-icons
              class="badge"
              name="question"
              color="#A4B3C1"
              (click)="openModal()"
            ></app-icons>
          </span>
        </div>
        <input
          *ngIf="isElectricBillOwner === false && !userDontKnowCpf"
          type="cpf"
          maxlength="14"
          class="input md"
          id="cpf"
          formControlName="cpf"
          placeholder="000.000.000-00"
          mask="000.000.000-00"
        />

        <div class="wrapper-slide-togle">
          <div *ngIf="userDontKnowCpf && isElectricBillOwner === false">
            <label
              *ngIf="!imageInvoice.nameImage"
              for="file-upload-desktop"
              class="btn bulbe lg"
              id="desktop-file"
            >
              Enviar conta de luz
              <app-icons name="camera" color="#FFF"></app-icons>
              <input
                #imageInputDesktop
                id="file-upload-desktop"
                type="file"
                accept="image/*, application/pdf"
                (change)="processFile(imageInputDesktop)"
              />
            </label>

            <div
              *ngIf="
                imageInvoice.nameImage &&
                userDontKnowCpf &&
                isElectricBillOwner === false
              "
              class="wrapper-image-invoice"
            >
              <div
                class="content-image"
                [ngClass]="{ cursor: isFormatImage }"
                (click)="handleOpenModalZoomImage(imageInvoice.base64)"
              >
                <app-icons
                  name="image-invoice-default"
                  color="#08068D"
                ></app-icons>
                <span>minha-fatura.{{ imageInvoice.formatImage }}</span>
              </div>

              <button class="btn-close" (click)="resetSendImageInvoice()">
                <app-icons name="trash" color="#8699AC"></app-icons>
              </button>
            </div>
          </div>

          <mat-slide-toggle
            *ngIf="isElectricBillOwner === false"
            formControlName="userDontKnowCpf"
            id="slide-toggle"
          >
            Não sei o CPF do titular da conta.
          </mat-slide-toggle>
        </div>

        <p
          class="error-message sm"
          *ngIf="
            !userDontKnowCpf && showCpfError && isElectricBillOwner === false
          "
        >
          CPF inválido.
        </p>
        <p
          class="error-message sm"
          *ngIf="
            !userDontKnowCpf &&
            isElectricBillOwner === false &&
            showCpfEqualError
          "
        >
          O CPF do titular deve ser diferente do seu CPF.
        </p>
      </div>
    </form>
  </div>

  <div>
    <mat-divider color="#C2CCD6"></mat-divider>
    <div class="wrapper-btn">
      <div></div>
      <button
        #thirdSubmitButton
        class="btn bulbe lg no-responsive-icon"
        id="third-form-submit-button"
        (click)="onSubmit()"
        [disabled]="!isFormValid()"
      >
        <span> Próximo </span>
        <app-icons
          [showInResponsiveScreen]="false"
          name="arrow-right"
          [color]="!isFormValid() ? '#C2CCD6;' : '#08068D'"
        ></app-icons>
      </button>
    </div>
  </div>
</div>

<app-modal id="modal-cpf-fatura" [showBtnCloseModal]="true">
  <div class="wrapper-modal-cpf-fatura">
    <h3>CPF do Titular</h3>
    <img src="../../assets/images/exemplo-fatura-cemig.png" alt="" />
    <p>
      Certifique-se de que você está fornecendo o CPF do titular da conta de luz
      durante o cadastro. Para isso, verifique o número do documento no
      cabeçalho da fatura cemig mais recente.
    </p>
  </div>
</app-modal>

<div class="wrapper-btn-help" *ngIf="showToastNotificationChat">
  <button class="btn primary xl" (click)="openToastNotification()">
    <app-icons name="?" color="#FFF"></app-icons>
  </button>
</div>

<ng-template #customToast>
  <div class="toast-notification-card-header">
    <img src="../../../assets/images/user-agent-wpp.png" alt="" />
    <h3>Precisa de ajuda?</h3>
  </div>
  <div class="toast-notification-card-content">
    <p>Clique e fale com nosso time de consultores via WhatsApp.</p>
  </div>
  <div class="toast-notification-card-footer">
    <button class="btn primary xs" (click)="openChatWpp()">
      <app-icons name="whatsapp" color="#FFF"></app-icons>
      Tirar dúvidas
    </button>
  </div>
</ng-template>

<app-modal-zoom-image [base64]="currentImageZoom" />
