import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class InterScriptLoaderService {
    private renderer: Renderer2;
    private scriptLoaded = false;

    constructor(private rendererFactory: RendererFactory2, private route: ActivatedRoute) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
    }

    loadScript() {
        if (this.scriptLoaded) return; // Evita carregar múltiplas vezes

        this.route.queryParams.subscribe((params) => {
            if (params['utm_source'] === 'inter') {
                const script = this.renderer.createElement('script');
                script.src =
                    'https://cdn.bancointer.com.br/affiliate-integrator-tracker-front-lib/latest/index.js';
                script.async = true;
                script.onload = () => {
                    (window as any).interShoppingAffiliate?.init(
                        'bulbeenergiacombr',
                        'https://cadastro.bulbeenergia.com.br'
                    );
                };
                this.renderer.appendChild(document.body, script);
                this.scriptLoaded = true; // Marca como carregado
            }
        });
    }
}