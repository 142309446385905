import { Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClickSendImageInvoiceProps } from 'src/app/components/sections/upload-invoice/upload-invoice.component';
import { Contact } from 'src/app/services/form/form.service';
import { ModalZoomImageService } from 'src/app/services/modal-zoom-image/modal-zoom-image.service';
import { UploadImageProps } from 'src/app/services/upload-image/upload-image.models';
import {  UploadImageService } from 'src/app/services/upload-image/upload-image.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-choose-action',
  templateUrl: './choose-action.component.html',
  styleUrls: ['./choose-action.component.scss']
})
export class ChooseActionComponent {

  @Input() contact!: Contact;
  @Output() nextStep2 = new EventEmitter<number>();
  @Output() isStepSuccess = new EventEmitter<boolean>(false);

  showUploadInvoice: boolean = false;
  sendImageInvoice: boolean = false;
  viewIsIframe: boolean = false;
  currentImageZoom: string | ArrayBuffer | null = "";

  private destroyRef = inject(DestroyRef);

  constructor(
    private uploadImageService: UploadImageService,
    private modalZoomImageService: ModalZoomImageService,
    private viewIframeService: ViewIframeService,
  ) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  goToNextStep(step: number) {
    this.openChat(step);
    this.nextStep2.next(step);
  }

  openApp(n: number) {
    switch (n) {
      case 1:
        window.open('https://app.bulbe.com.br/ios');
        break;
      case 2:
        window.open('https://app.bulbe.com.br/android');
        break;

      default:
        break;
    }
  }

  openChat(n: number) {
    switch (n) {
      case 1:
        window.open('https://api.whatsapp.com/send?phone=5531984476424&text=Olá! Quero falar com a Bulbe.')
        break;
      case 3:
        window.open('https://api.whatsapp.com/send?phone=5531984476424&text=Olá! Quero saber informações sobre meu cadastro na Bulbe.')
        break;

      default:
        break;
    }
  }

  goToUploadInvoice() {
    this.showUploadInvoice = true;
  }

  sendInvoiceImage({ base64, formatImage }: ClickSendImageInvoiceProps) {
    const payload: UploadImageProps = {
      hubSpotNegocioId: this.contact?.deal?.id || '',
      payload: {
        faturaCemigBase64: base64,
        extensaoArquivo: formatImage,
      }
    }

    this.uploadImageService.uploadImage(payload)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: () => {
        this.sendImageInvoice = true;
        this.isStepSuccess.emit(true)
        this.uploadImageService.resetImageInvoiceSubject()
      },
      error: err => {
        this.uploadImageService.setErroImageInvoiceSubject("Erro ao enviar a imagem.")
      },
    })
  }

  handleCancelUploadImageInvoice() {
    // Voltar para pagina anterior
    this.showUploadInvoice = false;
    this.uploadImageService.resetImageInvoiceSubject()
    this.isStepSuccess.emit(false);
  }

  openModalZoomImage(path:string | ArrayBuffer | null) {
    this.currentImageZoom = path
    this.modalZoomImageService.open()
  }

  handleClickBack() {
    this.showUploadInvoice = false;
    this.sendImageInvoice = false;
    this.isStepSuccess.emit(false);
  }
}
