import { Component, Input, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Contact } from '../services/form/form.service';

@Component({
  selector: 'app-user-already-exist-stepper',
  templateUrl: './user-already-exist-stepper.component.html',
  styleUrls: ['./user-already-exist-stepper.component.scss']
})
export class UserAlreadyExistStepperComponent {

  step!: number;
  selectedStepIndex!: number;
  dealCpfOrCnpjDuplicated: boolean = false;
  isStepSuccess: boolean = false;

  @ViewChild('stepper2') stepper2!: MatStepper;
  @Input() contact!: Contact;
  @Input() contactAlreadyExist!: boolean;
  @Input() incompleteDeal!: boolean;

  nextStep(event: any) {
    // skip contact screen
    if(event != 1 && event != 3) {
      this.step = event;
      this.stepper2.next();
    }
  }

  receiveContact(event: any) {
    this.contact = event;
  }

  changeSelectedIndex(event: any) {
    this.stepper2.previous();
  }

  setStep() {
    this.step = 2;
  }

  goToFinalStep(event: any) {
    if(event.code == "cpf_or_cnpj_already_exist") {
      this.dealCpfOrCnpjDuplicated = true;
    }
    this.stepper2.next();
  }

}
