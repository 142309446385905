export const partnersWithoutCoupon = [
  'afas',
  'cefs',
  'edmil',
  'gerdau',
  'csenergy',
  'inter',
  'direcional',
  'farmaciadavaleria',
  'grautecnico',
  'semlimite',
  'soluciona',
  'eficacia',
  'tarin',
  'verace',
  'grupocdm',
  'gps',
  'base2',
  'rara',
  'adunimontes',
  'vli',
  'araujo',
  'milplan',
  'dubeneficios',
  'monteverde',
  'faveni',
  'jabuta',
  'brinde',
  'vilma',
  'baleia',
  'pequenosnegocios',
  'netlex',
  'villalobos',
  'maira',
  'allya',
  'missionbrasil',
  'livelo',
  'crm',
  'one',
];
