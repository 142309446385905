<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-notification" *ngIf="contactAlreadyExist">
    <div class="notification">
      <span>
        Continue seu cadastro para economizar com a Bulbe.
      </span>
    </div>
  </div>

  <div class="wrapper-title">
    <app-title [showHelpText]="true" [currentStep]="4" title="Está quase lá!"></app-title>
    <p class="subtitle" *ngIf="shouldHideCouponScreen">Concorde com o Termo de Adesão para começar a economizar.</p>
  </div>

  <div *ngIf="!shouldHideCouponScreen">
    <div [formGroup]="couponForm" class="coupon-container">
      <label for="name" class="form-label">Cupom de indicação:</label>
      <div class="coupon-container-input-wrapper" [ngClass]="{'error': hasInputError }">
        <input type="name" maxlength="9" id="coupon" formControlName="coupon" placeholder="Cupom de indicação"
          pattern="[a-zA-Z0-9]*" (input)="checkCouponInput($event)" />
        <app-icons [name]="showIcon ? 'trash' : 'arrow-right'" [color]="couponInputIconColor" (click)="onIconClick()">
        </app-icons>
      </div>
    </div>

    <app-coupon-alert *ngIf="isCouponInvalid" iconName="error" title="Indicação não identificada">
    </app-coupon-alert>

    <app-coupon-alert *ngIf="hasInputError" iconName="error" title="Formato Inválido.">
    </app-coupon-alert>

    <app-coupon-alert
      *ngIf="!hasInputError && isCouponInvalid === false && referralCouponResponse && referralCouponResponse.clienteNome !== null"
      iconName="check-icon" title="Indicação de:" [message]="referralCouponResponse.clienteNome">
    </app-coupon-alert>

    <app-coupon-alert iconName="energy" title="Energia Bulbe:"
      [message]="'Até 15% de desconto no consumo da conta de luz Bulbe.'">
    </app-coupon-alert>
  </div>

  <app-wrapper-term-policy (scrolledToBottom)="onScrolledToBottom()">
    <h3 class="term-title">Termo de adesão</h3>

    <p>
      O presente <u>Termo de Adesão</u> tem como Objeto a adesão, pelo(a) Aderente Locatário(a),
      ao serviço de geração compartilhada, correspondente à locação de módulos fotovoltaicos
      proporcionais ao seu consumo histórico de energia elétrica da Cooperativa (“Cotas de
      Usinas Solares”). O volume de energia gerado pela sua cota parte será injetado na rede
      da distribuidora e compensado em sua fatura de energia, gerando uma percepção de
      economia 1 para o(a) Aderente Locatário(a).
    </p>

    <p>
      Ao realizar a adesão, o(a) Aderente Locatário(a) concorda em aderir à Bulbe Energia
      Cooperativa (“Cooperativa”), inscrita no CPNJ/MF sob o nº. 43.581.971/0001-85, sediada
      na Rua Araguari, nº. 1.156/804, bairro Santo Agostinho, BH/MG, no papel de Aderente
      Locatário(a). Esta vinculação é condição indispensável para a utilização dos serviços de
      compensação de energia elétrica, em cumprimento à Lei Federal 14.300 de 6 janeiro de
      2022.
    </p>

    <p>O(A) Aderente Locatário(a), pessoa física, já devidamente qualificado(a), declara para os
      devidos fins de direito, sob as penas da lei, que as informações, os documentos e os
      dados apresentados são verdadeiros e autênticos, além de possuir plenos direitos,
      capacidade civil, poder e autoridade para celebrar o presente <u>Termo de Adesão.</u></p>

    <p>Por fim, em conformidade com o art. 684 do Código Civil, o(a) Aderente Locatário(a)
      outorga procuração, de forma irrevogável e irretratável, com poderes gerais à Cooperativa
      para: <b>(i)</b> representação nas deliberações e votações em assembleia da Cooperativa, com
      poderes especiais para o recebimento de citações, intimações e notificações provenientes
      de qualquer processo judicial e/ou administrativo por força da sua condição de
      Cooperado(a) e da adesão aos Termos e Condições de Uso e da Política de Privacidade;
      <b>(ii)</b> representação perante à Concessionária de Energia Local, ANEEL e quaisquer
      terceiros, sejam entes da administração pública ou privados para (a) realizar quaisquer
      ações necessárias, podendo assinar instrumentos e acordos, ceder, transferir, transigir e
      renunciar a direitos, referente a sua participação no Sistema de Compensação de Energia
      Elétrica (SCEE), com relação à(s) sua(s) unidade(s) consumidora(s), incluindo, mas não
      se limitando a <b>(a)</b> alterar formas de recebimento de fatura; <b>(b)</b> solicitar 2ª (segunda) via de
      fatura; <b>(c)</b> gerir a conta de consumo de energia e da cota contratada, podendo alocar o
      percentual de créditos de energia destinados segundo o perfil de consumo e alterar o
      valor e/ou quantidade de sua cota, bem como realizar eventual troca de titularidade,; <b>(iii)</b>
      inclusão, exclusão, eliminação ou demissão do(a) Aderente Locatário(a) perante a
      Cooperativa nos termos do Estatuto da Cooperativa; e, <b>(iv)</b> enfim, todos os atos e
      diligências especificamente e exclusivamente, que se façam necessários para o bom
      funcionamento da Cooperativa e/ou de qualquer outro instituto jurídico autorizado e
      reconhecido pelas entidades competentes, podendo ser representado(a) de forma
      isolada, bem como substabelecer, com ou sem reservas de poderes, o presente Termo de
      Adesão, para o fiel cumprimento deste mandato.
    </p>

    <p>O(A) Aderente Locatário(a) tem ciência que poderá receber uma quantidade de créditos
      de energia maior que seu consumo histórico, independente de recebimento de
      comunicação prévia por parte da Cooperativa.</p>

    <p>O(A) Aderente Locatário(a) poderá solicitar a rescisão do presente <u>Termo de Adesão</u> a
      qualquer tempo, desde que devidamente cumpridas as condições constantes do <u>Termos
        e Condições de Uso</u>. O(A) Aderente Locatário(a) tem ciência que continuará responsável pelo pagamento dos
      créditos
      de energia já injetados em sua unidade consumidora, até o
      momento em que eles sejam totalmente consumidos ou pagos, independente da data da
      comunicação do pedido de rescisão.</p>

    <p>O(A) Aderente Locatário(a) deverá, obrigatoriamente, notificar a Cooperativa, antes de
      realizar qualquer alteração, troca de titularidade ou encerramento da unidade
      consumidora que recebe os Créditos de Energia da Cooperativa, junto à CEMIG, para que
      sejam feitas as devidas tratativas e alterações previstas na legislação vigente.</p>

    <p>A Cooperativa declara que praticará todas as medidas necessárias para que este <u>Termo
        de Adesão</u>, celebrado pelo(a) Aderente Locatário (a) sob seu conhecimento, seja
      registrado em sua sede no respectivo Livro de Matrícula.</p>

    <p>O(A) Aderente Locatário(a) declara que, mediante assinatura do presente <u>Termo de
        Adesão</u>, está ciente e de acordo com os <u>Termos e Condições de Uso</u>, com a <u>Política de
        Privacidade</u> e <u>Regulamento de Campanha</u> vigente, constantes em nosso site ou
      plataforma.</p>
    <hr>

    <p class="footnotes">
      1 A percepção de benefício auferido com o aluguel das cotas da usina solar é de até 15% (quinze por cento) de
      economia e leva em
      consideração a tarifa vigente compensável disponibilizada pela CEMIG, sem a incidência de qualquer tributo. O(A)
      Consórcio/Cooperativa terá plena autonomia para injetar na(s) instalação(ões) do(a) Aderente Locatário(a) a
      quantidade de Créditos de
      Energia que considerar apropriado, correspondente à quantidade de módulos locados.
    </p>

  </app-wrapper-term-policy>

  <a class="term-link" href="{{ bulbeTermLink }}" target="_blank">
    <span>Termo de adesão</span>
  </a>


  <mat-divider color="#C2CCD6"></mat-divider>
  <div class="wrapper-btn">
    <button class="btn bulbe lg no-responsive-icon" id="button-aceite" [disabled]="isButtonDisabled"
      (click)="onSubmit()">
      <span>
        {{ textBtn }}
      </span>
      <app-icons [showInResponsiveScreen]=false name="arrow-right"
        [color]="isButtonDisabled ? '#C2CCD6;' : '#08068D'"></app-icons>
    </button>
  </div>
</div>

<div class="wrapper-btn-help" *ngIf="showToastNotificationChat">
  <button class="btn primary xl" (click)="openToastNotification()">
    <app-icons name="?" color="#FFF"></app-icons>
  </button>
</div>

<ng-template #customToast>
  <div class="toast-notification-card-header">
    <img src="../../../assets/images/user-agent-wpp.png" alt="">
    <h3>Precisa de ajuda?</h3>
  </div>
  <div class="toast-notification-card-content">
    <p>Clique e fale com nosso time de consultores via WhatsApp.</p>
  </div>
  <div class="toast-notification-card-footer">
    <button class="btn primary xs" (click)="openChatWpp()">
      <app-icons name="whatsapp" color="#FFF"></app-icons>
      Tirar dúvidas
    </button>
  </div>
</ng-template>
