import {
  ToastNotificationService,
  ToastNotificationTypes,
} from 'src/app/services/toast-notification/toast-notification.service';
import { deleteKeysFromObject } from 'src/app/utils/delete-object-keys';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as cpfCnpjValidator from 'cpf-cnpj-validator';
import { Contact, Deal, FormService } from 'src/app/services/form/form.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { ProcessFileService } from 'src/app/services/process-file/process-file.service';
import {
  ImageInvoice,
  UploadImageProps,
} from 'src/app/services/upload-image/upload-image.models';
import { UploadImageService } from 'src/app/services/upload-image/upload-image.service';

export interface ClickSendBillInvoiceProps {
  formatImage: string;
  base64: string | ArrayBuffer | null;
}

@Component({
  selector: 'app-third-form',
  templateUrl: './third-form.component.html',
  styleUrls: ['./third-form.component.scss'],
})
export class ThirdFormComponent implements OnInit {
  @ViewChild('customToast', { static: true }) customToast!: TemplateRef<any>;

  @Input() cpfNotFound: boolean = false;
  @Input() urlParams: any;
  @Input() contact!: Contact;
  @Input() deal!: Deal;
  @Input() contactAlreadyExist!: boolean;
  @Input() incompleteDeal!: boolean;
  @Input() showToastNotificationChat: boolean = false;
  @Output() nextStep = new EventEmitter<Contact>();
  @Output() nextStep4 = new EventEmitter<Contact>();
  @Output() emitUrlImage: EventEmitter<string | ArrayBuffer | null> =
    new EventEmitter<string | ArrayBuffer | null>();
  @ViewChild('thirdSubmitButton', { static: true })
  thirdSubmitButton!: ElementRef;

  isSelectedForHome: boolean = false;
  isSelectedForCompany: boolean = false;
  isElectricBillOwner?: boolean;
  userDontKnowCpf: boolean = false;
  showCpfError: boolean = false;
  showMyCpfError: boolean = false;
  showCpfEqualError: boolean = false;
  showCnpjError: boolean = false;
  showDuplicatedCnpjError: boolean = false;
  imageInvoice: ImageInvoice = {
    nameImage: '',
    formatImage: '',
    base64: '',
    size: 0,
  };
  isFormatImage: boolean = false;

  idChatNotification: string = '55D5D1D8D1F8';
  typeToasNotificationWpp: ToastNotificationTypes = ToastNotificationTypes.INFO;
  showMyCpfErrorAlreadyExist: boolean = false;
  viewIsIframe: boolean = false;

  currentImageZoom: string | ArrayBuffer | null = '';
  errorUploadImage: string = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private formService: FormService,
    private modalService: ModalService,
    private viewIframeService: ViewIframeService,
    private toastNotificationService: ToastNotificationService,
    private processFileService: ProcessFileService,
    private uploadImageService: UploadImageService,
  ) {
    this.thirdSubmitButton = new ElementRef(null);

    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  thirdForm = new FormGroup({
    isElectricBillOwner: new FormControl(null),
    myCpf: new FormControl(null),
    cpf: new FormControl(null),
    cnpj: new FormControl(null),
    userDontKnowCpf: new FormControl(false),
  });

  handleOpenModalZoomImage(path: string | ArrayBuffer | null) {
    this.currentImageZoom = path;
    if (this.imageInvoice.formatImage === 'pdf') return;

    if (path?.toString().includes('base')) this.emitUrlImage.emit(path);
    else this.emitUrlImage.emit(`../${path}`);
  }

  sendInvoiceImage({ base64, formatImage }: ClickSendBillInvoiceProps) {
    const payload: UploadImageProps = {
      hubSpotNegocioId: this.contact?.deal?.id || '',
      payload: {
        faturaCemigBase64: base64,
        extensaoArquivo: formatImage,
      },
    };

    this.uploadImageService
      .uploadImage(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.uploadImageService.resetImageInvoiceSubject();
        },
        error: (err) => {
          this.uploadImageService.setErroImageInvoiceSubject(
            'Erro ao enviar a imagem.',
          );
        },
      });
  }

  selectHome() {
    this.isSelectedForHome = true;
    this.isSelectedForCompany = false;
  }

  selectCompany() {
    this.isSelectedForCompany = true;
    this.isSelectedForHome = false;
  }

  selectElectricBillOwner(prop: boolean = true) {
    this.isElectricBillOwner = prop;
  }

  async processFile(imageInput: HTMLInputElement) {
    if (!imageInput || !imageInput.files || imageInput.files.length === 0) {
      console.error('No file input detected or file is missing');
      return;
    }

    try {
      const result = await this.processFileService.processFile(imageInput);

      if (result.success) {
        this.imageInvoice = result.imageData;
      } else {
        console.error('Error processing file:', result.error);
      }
    } catch (error) {
      console.error('Error processing file:', error);
    }
  }

  ngOnInit(): void {
    this.uploadImageService.imageInvoice$.subscribe((invoceImage) => {
      this.imageInvoice = invoceImage;
      this.uploadImageService.resetErroImageInvoiceSubject();
    });

    this.uploadImageService.erroImageInvoice$.subscribe((err) => {
      this.errorUploadImage = err;
    });

    this.thirdForm.get('userDontKnowCpf')!.valueChanges.subscribe((value) => {
      this.userDontKnowCpf = value ?? false; // Atualiza a propriedade com o novo valor
    });

    if (this.isSelectedForHome) {
      this.thirdForm.controls['cpf'].setValidators([Validators.required]);
    } else {
      this.thirdForm.controls['cpf'].clearValidators();
      this.thirdForm.get('myCpf')!.setValue(null);
      this.thirdForm.get('cpf')!.setValue(null);
    }

    if (this.isSelectedForCompany) {
      this.thirdForm.controls['cnpj'].setValidators([Validators.required]);
    } else {
      this.thirdForm.controls['cnpj'].clearValidators();
      this.thirdForm.get('cnpj')!.setValue(null);
    }

    if (this.isElectricBillOwner) {
      this.thirdForm.get('cpf')!.setValue(this.thirdForm.get('myCpf')!.value);
      this.thirdForm.controls['cpf'].disable();
      document
        .querySelector('#document-input-label')!
        .classList.add('disabled');
      this.showCpfEqualError = false;
    } else {
      this.thirdForm.get('cpf')!.setValue(null);
      this.thirdForm.controls['cpf'].enable();
      // document.querySelector("#document-input-label")!.classList.remove('disabled');
    }

    this.thirdForm
      .get('cpf')!
      .valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm
      .get('cnpj')!
      .valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm
      .get('myCpf')!
      .valueChanges.subscribe(this.validateDocumentsInputs.bind(this));

    this.thirdForm.get('myCpf')!.valueChanges.subscribe((value: any) => {
      this.showMyCpfErrorAlreadyExist = false;
      this.showMyCpfError = false;

      if (this.isElectricBillOwner) {
        this.thirdForm.get('cpf')!.setValue(value);
      }

      if (value && value.length == 11) {
        if (!this.validateCpf(value)) {
          this.showMyCpfError = true;
        } else {
          this.showMyCpfError = false;

          const payload: Contact = {
            contact_id: this.contact.id!,
            cpf: this.thirdForm.get('myCpf')!.value!,
          };

          this.formService
            .updateContactCpf(payload)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => (this.showMyCpfErrorAlreadyExist = false),
              error: (error) => {
                if (
                  error.status == 403 &&
                  error.error.code == 'cpf_or_cnpj_already_exist'
                ) {
                  this.showMyCpfErrorAlreadyExist = true;
                }
              },
            });
        }
      }
    });

    this.thirdForm.get('cpf')!.valueChanges.subscribe((value: any) => {
      this.showCpfError = false;
      this.showCpfEqualError = false;

      if (value && value.length == 11) {
        if (!this.validateCpf(value)) {
          this.showCpfError = true;
        } else {
          this.showCpfError = false;
        }
      }
    });

    this.thirdForm.get('cnpj')!.valueChanges.subscribe((value: any) => {
      this.showCnpjError = false;

      if (value && value.length === 14) {
        if (!this.validateCnpj(value)) {
          this.showCnpjError = true;
        } else {
          this.showCnpjError = false;
        }
      }
    });
  }

  getUser() {
    this.formService.getUser(this.contact.email!, "email").pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.contact = response
      }
    })
  }

  isFormValid(): boolean {
    let cpfVal = this.thirdForm.get('cpf')!.value;
    let myCpfVal = this.thirdForm.get('myCpf')!.value;
    let cnpjVal = this.thirdForm.get('cnpj')!.value;
    let userDontKnowCpfVal = this.thirdForm.get('userDontKnowCpf')!.value;

    if (this.isSelectedForHome) {
      if (this.isElectricBillOwner) return this.validateCpf(myCpfVal!);

      if (userDontKnowCpfVal) {
        return this.imageInvoice.size !== 0 && this.validateCpf(myCpfVal!);
      }

      return this.validateCpf(cpfVal!) && this.validateCpf(myCpfVal!);
    }

    if (this.isSelectedForCompany && this.validateCnpj(cnpjVal!)) {
      return true;
    }

    if (!this.isSelectedForHome && !this.isSelectedForCompany) {
      return false;
    }

    if (this.showMyCpfErrorAlreadyExist) return false;

    return false;
  }

  validateDocumentsInputs() {
    const nextButton = this.thirdSubmitButton.nativeElement;

    return this.isFormValid()
      ? nextButton.removeAttribute('disabled')
      : nextButton.setAttribute('disabled', 'true');
  }

  validateCpf(cpf: string): boolean {
    return cpfCnpjValidator.cpf.isValid(cpf);
  }

  validateCnpj(cnpj: string): boolean {
    return cpfCnpjValidator.cnpj.isValid(cnpj);
  }

  onSubmit() {
    const cpf: string | null = this.thirdForm.get('cpf')!.value;
    const myCpf: string | null = this.thirdForm.get('myCpf')!.value;
    const cnpj: string | null = this.thirdForm.get('cnpj')!.value;
    const userDontKnowCpfVal = this.thirdForm.get('userDontKnowCpf')!.value;

    this.showCpfError = false;
    this.showCnpjError = false;

    if (userDontKnowCpfVal) {
      this.sendInvoiceImage({
        base64: this.imageInvoice.base64,
        formatImage: this.imageInvoice.formatImage,
      });
    }

    if (this.contactAlreadyExist && this.incompleteDeal) {
      this.checkToNewDeal(myCpf, cnpj);
    } else {
      if (!this.isSelectedForHome && this.isSelectedForCompany) {
        if (cnpj!.length == 14) {
          if (!this.validateCnpj(cnpj!)) {
            this.showCnpjError = true;
            return;
          }
          const payload: Deal = {
            deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
            deal_id: this.deal.id,
            natureza_juridica: 'Pessoa Jurídica',
            cnpj: this.thirdForm.get('cnpj')!.value!,
            contact_id: this.contact.id!,
          };
          this.updateExistedDeal(payload);
        } else {
          this.showCnpjError = true;
        }
      } else {
        if (this.isSelectedForHome) {
          this.showCpfEqualError = false;
          this.showMyCpfError = false;
          this.showCpfError = false;

          if (!this.isElectricBillOwner && cpf! == myCpf!) {
            this.showCpfEqualError = true;
            return;
          } else if (
            !this.isElectricBillOwner &&
            cpf! != myCpf! &&
            !this.userDontKnowCpf
          ) {
            if (!this.validateCpf(cpf!)) this.showCpfError = true;
            if (!this.validateCpf(myCpf!)) this.showMyCpfError = true;
            if (this.showCpfError || this.showMyCpfError) return;

            if (cpf!.length == 11) {
              const payload: Deal = {
                deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${cpf}`,
                deal_id: this.deal.id,
                natureza_juridica: 'Pessoa Física',
                cpf: this.thirdForm.get('cpf')!.value!,
                contact_id: this.contact.id!,
              };
              this.updateExistedDeal(payload);
            } else {
              this.showCpfError = true;
              return;
            }

            if (myCpf!.length < 11) {
              this.showMyCpfError = true;
              return;
            }
          } else if (this.userDontKnowCpf && !this.isElectricBillOwner) {
            if (!this.validateCpf(myCpf!)) this.showMyCpfError = true;
            if (this.showMyCpfError) return;

            if (myCpf!.length !== 11) {
              this.showMyCpfError = true;
            }

            const payload: Deal = {
              deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${myCpf}`,
              deal_id: this.deal.id,
              natureza_juridica: 'Pessoa Física',
              cpf: myCpf,
              contact_id: this.contact.id!,
            };

            this.updateExistedDeal(payload);
          } else if (this.isElectricBillOwner) {
            if (myCpf!.length == 11) {
              if (!this.validateCpf(myCpf!)) this.showMyCpfError = true;
              if (this.showMyCpfError) return;

              const payload: Deal = {
                deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${myCpf}`,
                deal_id: this.deal.id,
                natureza_juridica: 'Pessoa Física',
                cpf: myCpf,
                contact_id: this.contact.id!,
              };
              this.updateExistedDeal(payload);
            } else {
              this.showCpfError = true;
            }
          }
        }

        if (this.isSelectedForCompany) {
          deleteKeysFromObject(
            [
              'interClickRef',
              'hs_facebook_click_id',
              'hs_google_click_id',
              'sfnsn',
              'vantanges',
              'cookies',
              '_gl',
              'gtm_debug',
            ],
            this.urlParams,
          );

          if (cnpj!.length == 14) {
            if (!this.validateCnpj(cnpj!)) {
              this.showCnpjError = true;
              return;
            }
            const payload: Deal = {
              deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
              cnpj: this.thirdForm.get('cnpj')!.value!,
              contact_id: this.contact.id!,
              deal_stage: '1777306',
              ...this.urlParams,
            };

            this.formService
              .createNewDeal(payload)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe({
                next: (response: any) => {},
              });
          } else {
            this.showCnpjError = true;
          }
        }
      }
    }
  }

  checkToNewDeal(cpf: string | null, cnpj: string | null) {
    if (this.isSelectedForHome) {
      if (cpf!.length == 11) {
        if (!this.validateCpf(cpf!)) {
          this.showCpfError = true;
          return;
        }
        const payload: Deal = {
          deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${cpf}`,
          cpf,
          contact_id: this.contact.id!,
          deal_stage: '1777306',
          deal_id: this.contact?.deal?.id,
        };
        this.updateExistedDeal(payload);
      } else {
        this.showCpfError = true;
      }
    }
    if (this.isSelectedForCompany) {
      if (cnpj!.length == 14) {
        if (!this.validateCnpj(cnpj!)) {
          this.showCnpjError = true;
          return;
        }
        const payload: Deal = {
          deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
          cnpj,
          contact_id: this.contact.id!,
          deal_stage: '1777306',
          deal_id: this.contact?.deal?.id,
        };
        this.updateExistedDeal(payload);
      } else {
        this.showCnpjError = true;
      }
    }
  }

  createNewDeal(payload: Deal) {
    this.formService
      .createNewDeal(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.toastNotificationService.dismiss('55D5D1D8D1F8');
          if (this.cpfNotFound) {
            this.nextStep4.next(response);
          } else {
            this.nextStep.next(response.deal);
          }
        },
        error: (error) => {
          if (
            error.status == 403 &&
            error.error.code == 'cpf_or_cnpj_already_exist'
          ) {
            this.nextStep.next(error.error);
          }
        },
      });
  }

  updateExistedDeal(payload: Deal) {
    if (this.cpfNotFound) {
      payload.deal_stage = '3521715';
    }
    this.formService
      .updateDeal(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.getUser();

          this.nextStep.next(this.contact.deal!);
          this.nextStep4.next(this.contact);
          this.toastNotificationService.dismiss('55D5D1D8D1F8');
        },
        error: (error) => {
          if (
            error.status == 403 &&
            error.error.code == 'cpf_or_cnpj_already_exist'
          ) {
            this.showDuplicatedCnpjError = true;
            return;
          }
        },
      });
  }

  getTitle() {
    if (this.cpfNotFound && this.contact)
      return `${this.contact.firstname}, não encontramos o CPF fornecido...`;
    else if (this.cpfNotFound) return 'Selecione aonde deseja economizar:';
    else return 'Informe os dados da conta de luz.';
  }

  openToastNotification(duration = 0) {
    setTimeout(
      () =>
        this.toastNotificationService.showTemplate({
          id: this.idChatNotification,
          type: 'info',
          duration: 0,
          borderStyle: 'left',
          template: this.customToast,
        }),
      duration,
    );
  }

  openChatWpp() {
    window.open(
      'https://wa.me//31984476424/?text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20consultor%20para%20economizar%20com%20a%20Bulbe.',
    );
  }

  ngOnDestroy(): void {
    this.toastNotificationService.dismiss('55D5D1D8D1F8');
  }

  openModal() {
    this.modalService.open();
  }

  resetSendImageInvoice() {
    this.imageInvoice = { nameImage: '', formatImage: '', base64: '', size: 0 };
    this.uploadImageService.resetImageInvoiceSubject();
    this.uploadImageService.setErroImageInvoiceSubject('');
  }
}
