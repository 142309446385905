<div id="video-section">
  <a href="https://www.youtube.com/watch?v=okG4n7irP9Q" target="_blank" class="icon-btn">
    <div class="container">
      <div class="wrapper-title">
        <img src="assets/images/play-icon.svg" />
        <h2>Conheça a Bulbe energia</h2>
        <div class="wrapper-icon">
        </div>
      </div>
    </div>
  </a>
</div>
