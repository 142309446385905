import { Component, OnInit } from '@angular/core';
import { ViewIframeService } from './services/view-iframe/view-iframe.service';
import { InterScriptLoaderService } from './services/inter/inter-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService, private interScriptLoader: InterScriptLoaderService) {
    this.viewIframeService.checkIsIframe();

    this.viewIframeService.viewIsIframe$.subscribe((value) => {
      this.viewIsIframe = value;
    });
  }

  ngOnInit() {
    this.interScriptLoader.loadScript();
  }
}
