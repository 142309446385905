import { Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatStepper } from '@angular/material/stepper';
import { Contact, Deal, FormService } from '../services/form/form.service';
import { ViewIframeService } from '../services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-cpf-not-found-stepper',
  templateUrl: './cpf-not-found-stepper.component.html',
  styleUrls: ['./cpf-not-found-stepper.component.scss']
})
export class CpfNotFoundStepperComponent implements OnInit{

  @ViewChild('stepper4') stepper4!: MatStepper;
  @Input() contact_id!: string;

  cpfNotFound: boolean = true;
  decryptedContactId!: string;
  contact!: Contact;
  deal!: Deal;
  contactAlreadyExist: boolean = true;
  viewIsIframe: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService, private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }

  ngOnInit(): void {
    this.decryptedContactId = this.decryptContact();
    this.getUser();
  }

  decryptContact() {
    const partnershipCode = parseInt(this.contact_id!.substring(0, 2), 16);
    const ecryptedCodeId = parseInt(this.contact_id!.substring(2, this.contact_id!.length), 16);
    const n = ecryptedCodeId-partnershipCode-452;
    return n.toString();
  }

  getUser() {
    this.formService.getUser(this.decryptedContactId, "contact_id").pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.contact = response.contact;
        this.deal = response.contact.deal;
      }
    })
  }

  nextStep(event: any) {
    this.stepper4.next();
  }

}
