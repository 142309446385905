<div class="wrapper" [ngClass]="{'iframe': viewIsIframe}">
  <div class="card-content">
    <div *ngIf="!viewIsIframe">
      <div class="custom-progress-bar" *ngIf="!(stepper.selectedIndex == 2 && contactAlreadyExist && !incompleteDeal)">
        <div class="progress-bar-fill"
          [ngStyle]="{'background-color': '#26D07C'}"
          [style.width]="stepper.selectedIndex === 4 ? '100%' : progressBarValue + '%'">
        </div>
      </div>
    </div>

    <mat-stepper linear #stepper [animationDuration]="'0'">
      <mat-step>
        <app-privacy-policy *ngIf="openPrivacyPolicy" (backToForm)="privacyPolicy($event)">
        </app-privacy-policy>

        <app-first-form
          *ngIf="!openPrivacyPolicy"
          [currentStep]="stepper.selectedIndex"
          [urlParams]="urlParams"
          (phone)="receivePhone($event)"
          (privacyPolicy)="privacyPolicy($event)"
          (nextStep)="nextStep($event)"
          (handleUserExistence)="handleUserExistence($event)"
          (contactAlreadyExist)="checkContact($event)"
          (deal)="receiveDeal($event)"
          (emailAndPhoneAlreadyExist)="checkEmailAndPhone($event)">
        </app-first-form>
      </mat-step>

      <mat-step>
        <app-second-form
          *ngIf="!emailAndPhoneAlreadyExist"
          [phone]="phone"
          [contact]="contact"
          [contactAlreadyExist]="contactAlreadyExist"
          (previousStep)="changeSelectedIndex($event)"
          (activeChat)="activeToastNotificationChat()"
          (nextStep)="nextStep($event)">
        </app-second-form>

        <app-choose-action *ngIf="emailAndPhoneAlreadyExist" [contact]="contact"></app-choose-action>
      </mat-step>

      <mat-step>
        <app-third-form
          [contact]="contact"
          [urlParams]="urlParams"
          [deal]="deal"
          [contactAlreadyExist]="contactAlreadyExist"
          [incompleteDeal]="incompleteDeal"
          (nextStep)="receiveDealAndGoToNextStep($event)"
          (emitUrlImage)="openModalZoomImage($event)"
          [showToastNotificationChat]="isActiveChatWorkHours"
          *ngIf="(!contactAlreadyExist) || (contactAlreadyExist && incompleteDeal)">
        </app-third-form>

        <app-user-already-exist-stepper
          *ngIf="contactAlreadyExist && !incompleteDeal"
          [contactAlreadyExist]="contactAlreadyExist"
          [incompleteDeal]="incompleteDeal"
          [contact]="contact">
        </app-user-already-exist-stepper>
      </mat-step>

      <mat-step>
        <app-choose-action *ngIf="dealCpfOrCnpjDuplicated"></app-choose-action>

        <app-fourth-form
          *ngIf="stepper.selectedIndex === 3 && !dealCpfOrCnpjDuplicated"
          [contact]="contact"
          [phone]="phone"
          [showToastNotificationChat]="isActiveChatWorkHours"
          (nextStep)="nextStep($event)">
        </app-fourth-form>
      </mat-step>

      <mat-step>
        <app-fifth-form *ngIf="stepper.selectedIndex === 4" [contact]="contact"></app-fifth-form>
      </mat-step>
    </mat-stepper>
  </div>
</div>
