import { AbTestingService } from './../../services/ab-testing/ab-testing.service';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AcceptContractPayload, Contact, FormService, ReferralCouponResponse } from 'src/app/services/form/form.service';
import {
  ToastNotificationService,
  ToastNotificationTypes,
} from 'src/app/services/toast-notification/toast-notification.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { partnersWithoutCoupon } from 'src/app/utils/partners-without-coupon';

declare var interShoppingAffiliate: any;
@Component({
  selector: 'app-fourth-form',
  templateUrl: './fourth-form.component.html',
  styleUrls: ['./fourth-form.component.scss'],
})
export class FourthFormComponent {
  @ViewChild('customToast', { static: true }) customToast!: TemplateRef<any>;
  @ViewChild('termsContainer') termsContainer!: ElementRef;

  @Input('contact') contact!: Contact;
  @Input() urlParams: any;
  @Input() contactAlreadyExist!: boolean;
  @Input() incompleteDeal!: boolean;
  @Input() phone: string = '';
  @Input() showToastNotificationChat: boolean = false;
  @Output() nextStep = new EventEmitter<any>();
  @Output() contactUpdated = new EventEmitter<Contact>();

  idChatNotification: string = '55D5D1D8D1F8';
  typeToasNotificationWpp: ToastNotificationTypes = ToastNotificationTypes.INFO;
  textBtn: string = '';
  showIcon = false;
  isCouponInvalid?: boolean = undefined;
  previousCouponValue = '';
  shouldHideCouponScreen = false;
  referralCouponResponse?: ReferralCouponResponse;
  inputInitialValue: string | null  = null;

  bulbeTermLink = 'https://bulbeenergia.com.br/termos-de-uso/';
  whatsappLink =
    'https://wa.me//31984476424/?text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20consultor%20para%20economizar%20com%20a%20Bulbe.';
  viewIsIframe: boolean = false;

  fourthForm = new FormGroup({
    termsAccepted: new FormControl(false),
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private formService: FormService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private toastNotificationService: ToastNotificationService,
    private abTestingService: AbTestingService,
    private viewIframeService: ViewIframeService,
  ) {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.abTestingService.checkVariation();

    this.abTestingService.variation$.subscribe((variation) => {
      this.textBtn = this.getTextButtonTestAB(variation);
    });

    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  ngOnInit() {
    this.shouldHideCouponScreen = partnersWithoutCoupon.some((partner) => {
      return this.contact?.deal?.utm_source?.includes(partner);
    });

    const utmContent = this.contact.deal?.utm_content;

    if (!utmContent) return;

    const utmContentSplit = utmContent.split('_')

    if (utmContentSplit[0] == 'CID') {
      this.inputInitialValue = utmContentSplit[1];
    } else {
      this.inputInitialValue = utmContent;
    }

    if (this.inputInitialValue && !this.shouldHideCouponScreen) {
      this.couponForm.patchValue({
        coupon: this.inputInitialValue
      })

      this.showIcon = true;

      this.verifyCoupon(this.inputInitialValue);
    }
  }

  couponForm = new FormGroup({
    coupon: new FormControl('', [
      Validators.pattern('^[a-zA-Z]{3}[0-9]{6}$'),
      Validators.maxLength(9),
    ]),
  });

  checkCouponInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    const sameContentAsBefore =
      value.toUpperCase() === this.previousCouponValue;
    this.showIcon = !!value;


    if (value === '') {
      this.referralCouponResponse = undefined;
    }

    if (this.couponForm.invalid) {
      this.isCouponInvalid = undefined;
    }

    if (this.isCouponInvalid && !sameContentAsBefore) {
      this.isCouponInvalid = false;
    }

    if (value !== '' && this.couponForm.valid && !sameContentAsBefore) {
      this.verifyCoupon(value);
    }
  }

  onIconClick() {
    if (this.showIcon) {
      this.couponForm.get('coupon')!.setValue(null);
      this.showIcon = false;
      this.referralCouponResponse = undefined;
      this.isCouponInvalid = undefined;
    }
  }

  verifyCoupon(coupon: string) {
    coupon = coupon.toUpperCase();
    this.previousCouponValue = coupon.toUpperCase();

    this.formService
      .validateReferralCoupon(coupon)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.referralCouponResponse = response as ReferralCouponResponse;

          this.isCouponInvalid = !response.validado
        }
      });
  }

  get hasInputError(): boolean {
    const control = this.couponForm.get('coupon');
    return !!control && control.invalid && (control.dirty || control.touched);
  }

  get isButtonDisabled(): boolean {
    return !this.fourthForm.get('termsAccepted')!.value || this.hasInputError || this.referralCouponResponse?.validado === false;
  }

  get couponInputIconColor() {
    if (this.hasInputError) {
      return '#DC2626';
    }

    return this.showIcon ? '#536679' : '#C2CCD6';
  }

  onScrolledToBottom() {
    this.fourthForm.get('termsAccepted')?.setValue(true);
  }

  onSubmit() {
    const coupon = this.couponForm.get('coupon')?.value;

    this.gtmService.pushTag({
      event: 'button-click',
      data: 'button-aceite',
    });

    this.gtmService.pushTag({
      event: 'event-conv-adesao',
      user_data: {
        email_address: this.contact.email, // em
        phone_number: `+55${this.phone}`, // ph
        address: {
          first_name: this.contact.firstname, // fn
          last_name: this.contact.lastname, // ln
        },
      },
      contact_id: this.contact.id,
    });

    const payload: AcceptContractPayload = {
      contact_id: this.contact.id!,
      cupom_indicacao: coupon ? coupon : undefined
    }

    this.formService
      .accContract(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.contact = {
            ...this.contact,
            aceite_do_termo_de_adesao:
              response.contact.aceite_do_termo_de_adesao,
            deal: response.contact.deal,
          };

          this.toastNotificationService.dismiss('55D5D1D8D1F8');
          if (this.contactAlreadyExist) {
            this.nextStep.next(this.contact);
          } else {
            this.nextStep.next(this.contact);
          }
        },
        complete: () => {
          this.sendRegisterOpenOrder();
        },
      });
  }

  sendRegisterOpenOrder() {
    const date: any = Date.now();
    const dateObject = new Date(date);

    dateObject.toISOString();

    interShoppingAffiliate.registerOpenOrder({
      orderRef: this.contact.id,
      currency: 'BRL', // USD or BRL
      total: 110.0,
      orderDateTime: dateObject,
    });
  }

  returnIframeView() {
    return window.self !== window.top;
  }

  openToastNotification() {
    this.toastNotificationService.showTemplate({
      id: this.idChatNotification,
      type: 'info',
      duration: 0,
      borderStyle: 'left',
      template: this.customToast,
    });
  }

  openChatWpp() {
    window.open(this.whatsappLink);
  }

  ngOnDestroy(): void {
    this.toastNotificationService.dismiss('55D5D1D8D1F8');
  }

  getTextButtonTestAB(variation: string) {
    return variation === 'btn-termo-adesao-vb'
      ? 'Economizar na conta de luz'
      : 'Concordar e contratar';
  }
}
